:root {

  /*
  dark: '#000',
  darker: '#546',
  medium: '#879',
  lighter: '#98a',
  light: '#fff',
  */

  /* Colors */
  --primaryColor: #02a378;
  --lightPrimaryColor: #61d1b3;
  --darkPrimaryColor: #0d644d;
  --secondaryColor: #7802a3;
  --lightSecondaryColor: #a46bb9;
  --darkSecondaryColor: #46005f;
  --red: #DD3F5B;

  --black: #111;
  --grey: #333;
  --lightGrey: #C4C4C4;
  --silver: #E1E4EA;
  --darkBlue: #31465F;
  --white: #FFFFFF;
  --light-blue: #00a1c9;
  
  /* Theme */
  --button-color: var(--white);
  --button-background-color: var(--secondaryColor);
  --button-click: var(--darkPrimaryColor);
  --link-color: var(--silver);
  --form-color: var(--primaryColor);
  --input-color: var(--grey);
  --input-background-color: var(--lightPrimaryColor);

  --font-family: "Helvetica" ,"Arial","sans-serif";

  /** Angular Theme **/
  --component-width-desktop: 460px;
  --component-width-mobile: 100%;

  --color-primary: var(--primaryColor);
  --color-primary-accent:var(--darkPrimaryColor);
  --color-primary-highlight: var(--lightPrimaryColor);
  
  --color-background: var(--black);

  --color-secondary:  var(--secondaryColor);
  --color-secondary-accent: var(--darkSecondaryColor);

  --color-danger: var(--red);
  --color-error:  var(--red);

  --gradient-blaze: linear-gradient(270deg, var(--lightPrimaryColor) 0% var(--primaryColor) 100%);


  --input-border: 1px solid #C4C4C4;
  --input-padding: 0.5em 0.5em 0.3em 1em;

  --box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  --button-height: 42px;

  --interactions-conversation-height: 250px;
}

.Section__container___3YYTG * {
  color: var(--grey);
  font-family: var(--font-family);
  font-size: 1.05rem;
  font-weight: 300;
}


.Anchor__a___1_Iz8 {
  min-width: 153px;
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 0;
  
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  
  text-transform: uppercase;
  padding: 14px;
  border: 2px solid var(--darkPrimaryColor);
  background-color: var(--primaryColor);
  color: var(--link-color);
  border-radius: 6px;
}

.Anchor__a___1_Iz8:hover {
  background-color: var(--lightPrimaryColor);
  color: var(--white);
}
.Button__button___vS7Mv {
	min-width: 153px;
	display: inline-block;
  margin-left: 8px;
	margin-bottom: 0;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
	background-image: none;
	color: var(--button-color);
	background-color: var(--button-background-color);
	text-transform: uppercase;
	padding: 14px 0;
	letter-spacing: 1.1px;
  border-radius: 6px;
	border: none;
}

.Button__button___vS7Mv:active {
	opacity: 1;
	background-color: var(--button-click);
}

.Button__button___vS7Mv:hover,
.Button__signInButton___3bUH-:hover {
	opacity: 0.8;
}

.Button__button___vS7Mv:disabled {
	opacity: 1;
	cursor: auto;
	background-color: var(--ion-color-primary-tint);
}

.Button__signInButton___3bUH- {
	position: relative;
	width: 100%;
	border-radius: 4px;
	margin-bottom: 10px;
	cursor: pointer;
	padding: 0;
	color: var(--darkSecondaryColor);
	box-sizing: content-box;
}

#Button__googleSignInButton___1YiCu {
	background-color: #4285f4;
	border: 1px solid #4285f4;
	color: var(--white);
}

#Button__googleSignInButton___1YiCu > .Button__signInButtonIcon___ihN75 {
	background-color: var(--white);
	border-radius: 4px 0 0 4px;
	height: 28px;
	width: 28px;
	padding: 12px;
}

#Button__auth0SignInButton___znnCj {
	background-color: var(--red);
	border: 1px solid var(--red);
	color: var(--white);
}

#Button__auth0SignInButton___znnCj > .Button__signInButtonIcon___ihN75 {
	border-radius: 4px 0 0 4px;
	height: 28px;
	width: 28px;
	padding: 12px;
	fill: var(--white);
}

#Button__facebookSignInButton___34Txh {
	background-color: #4267b2;
	border-color: #4267b2;
	color: var(--white);
}

#Button__facebookSignInButton___34Txh > .Button__signInButtonIcon___ihN75 {
	height: 33px;
	width: 18px;
	padding: 10px 14px;
}

#Button__amazonSignInButton___2EMtl {
	background-color: var(--primaryColor);
	border: none;
	color: var(--white);
}

#Button__amazonSignInButton___2EMtl > .Button__signInButtonIcon___ihN75 {
	padding: 10px;
	height: 32px;
	width: 32px;
}

#Button__oAuthSignInButton___3UGOl {
	background-color: var(--white);
	color: var(--darkSecondaryColor);
}

.Button__signInButtonIcon___ihN75 {
	position: absolute;
	left: 0;
	box-sizing: content-box;
}

.Button__signInButtonContent___xqTXJ {
	text-align: center;
	display: block;
	padding: 18px 0;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	line-height: initial;
}

.Form__formContainer___1GA3x {
  text-align: center;
  margin-top: 20px;
  margin: 5% auto 50px;
}

.Form__formSection___1PPvW {
  position: relative;
  margin-bottom: 20px;
  background-color: var(--form-color);
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  box-sizing: border-box;
}

.Form__formField___38Ikl {
  margin-bottom: 22px;
  background-color: var(--input-background-color);
  padding: 8px;
  border-radius: 6px;
}

.Form__formRow___2mwRs {
  margin-bottom: 12px;
}

@media only screen and (max-width: 599px) {
  .Form__formContainer___1GA3x {
    margin: 0;
  }

  .Form__formSection___1PPvW {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    box-shadow: none;
    border-radius: 0;
    min-width: auto;
  }
}
.Hint__hint___2XngB {
  color: var(--grey);
}

.Input__input___3e_bf {
  display: block;
  width: 100%;
  padding: 16px;
  color: var(--input-color);
  background-color: var(--input-background-color);
  background-image: none;
  border: 1px solid var(--lightGrey);
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.Input__input___3e_bf:disabled {
  color: #545454;
  background-color: var(--silver);
}

.Input__inputLabel___3VF0S {
  color: var(--input-color);
  background-color: var(--input-background-color);
  margin-bottom: 8px;
}

.Input__label___23sO8 {
  color: var(--input-color);
}

.Input__radio___2hllK {
  margin-right: 18px;
  vertical-align: bottom;
}

@media only screen and (max-width: 599px) {
  .Input__input___3e_bf {
    font-size: 16px;
  }
}
.Nav__navBar___xtCFA {
  position: relative;
  border: 1px solid transparent;
  border-color: var(--white);
  background-color: var(--white);
}

.Nav__navRight___1QG2J {
  text-align: right
}

.Nav__nav___2Dx2Y {
  padding: 10px;
}

.Nav__navItem___1LtFQ {
  display: inline-block;
  padding: 10px 5px;
  line-height: 20px;
  margin-right: 12px;
}
.PhotoPicker__photoPickerButton___2XdVn {
  width: 100%;
}

.PhotoPicker__photoPlaceholder___2JXO4 {
  border: 2px dotted var(--grey);
  padding: 64px 0 64px;
}

.PhotoPicker__photoPlaceholderIcon___3Et71 {
  text-align: center;
  opacity: 0.2;
}
.Section__container___3YYTG {
	font-weight: 400;
}

.Section__actionRow___2LWSU {
	margin-bottom: 15px;
}

.Section__sectionHeader___2djyg {
	color: var(--darkSecondaryColor);
	margin-bottom: 24px;
}

.Section__sectionHeaderHint___3Wxdc {
	color: var(--grey);
	font-weight: 400;
	margin-top: 4px;
}

.Section__sectionBody___ihqqd {
	margin-bottom: 30px;
}

.Section__sectionHeaderContent___1UCqa {
}

.Section__sectionFooter___1T54C {
	color: var(--grey);
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-start;
}

.Section__sectionFooterPrimaryContent___2r9ZX {
	margin-left: auto;
}

.Section__sectionFooterSecondaryContent___Nj41Q {
	margin-right: auto;
	align-self: center;
}

@media only screen and (max-width: 599px) {
	.Section__sectionFooter___1T54C {
		flex-wrap: wrap;
	}

	.Section__sectionFooterPrimaryContent___2r9ZX {
		width: 100%;
		margin-bottom: 32px;
	}

	.Section__sectionFooterPrimaryContent___2r9ZX > button {
		width: 100%;
	}

	.Section__sectionFooterSecondaryContent___Nj41Q {
		text-align: center;
		flex: 0 0 100%;
	}
}

.SelectInput__selectInput___3efO4 {
	display: flex;
}

.SelectInput__selectInput___3efO4 > input {
	flex: 1 1;
	border-radius: 0 3px 3px 0 !important;
}

.SelectInput__selectInput___3efO4 > select {
	padding: 16px 20px 16px 16px;
	color: var(--darkSecondaryColor);
	background-color: #fff;
	background-image: none;
	border: 1px solid var(--lightGrey);
	border-right: none;
	border-radius: 3px 0 0 3px;
	box-sizing: border-box;
	margin-bottom: 10px;
	-webkit-appearance: none;
	appearance: none;
	flex-basis: 22%;
	width: 1%;

	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%),
		linear-gradient(to right, #ccc, #ccc);
	background-position: calc(100% - 10px) calc(1em + 8px),
		calc(100% - 4px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
	background-size: 6px 5px, 6px 5px, 0px 1.5em;
	background-repeat: no-repeat;
}

.Strike__strike___1XV1b {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--lightGrey); 
  line-height: 0.1em;
  margin: 32px 0;
  color: var(--grey);
}

.Strike__strikeContent___10gLb {
  background: var(--form-color); 
  padding: 0 25px;
  font-weight: 500;
}
.Toast__toast___XXr3v {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  padding: 16px;
  background-color: var(--lightSecondaryColor);
  color: #fff;
  box-sizing: border-box;
}

.Toast__toast___XXr3v > span {
  margin-right: 10px;
}

.Toast__toastClose___18lU4 {
  margin-left: auto;
  align-self: center;
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  cursor: pointer;
}

.Toast__toastClose___18lU4::before,
.Toast__toastClose___18lU4::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: var(--lightGrey);
}

.Toast__toastClose___18lU4:hover::before,
.Toast__toastClose___18lU4:hover::after {
  background: var(--red);
}

.Toast__toastClose___18lU4::before {
  transform: rotate(45deg);
}
.Toast__toastClose___18lU4::after {
  transform: rotate(-45deg);
}
.Totp__totpQrcode___1crLx {
  text-align: center;
  margin-bottom: 30px;
}
/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

.XR__sumerianSceneContainer___3nVMt {
  width: 100%;
  height: 100%;
  position: relative;
}

.XR__sumerianScene___2Tt7- {
  width: 100%;
  height: 100%;
}

.XR__loadingOverlay___IbqcI {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--lightSecondaryColor);
}

.XR__loadingContainer___2Itxb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.XR__loadingLogo___Ub7xQ {
  margin-bottom: 20px;
  width: 80px;
  fill: var(--white);
}

.XR__loadingSceneName___3__ne {
  color: var(--white);
  margin: 0 2px 20px 2px;
}

.XR__loadingBar___2vcke {
  height: 2px;
  width: 100%;
  border-radius: 2px;
  background-color: var(--darkSecondaryColor);
}

.XR__loadingBarFill___3M-D9 {
  background-color: var(--light-blue);
  height: 100%;
  border-radius: 2px;
}

.XR__sceneErrorText___2y0tp {
  color: var(--red);
}

.XR__sceneBar___2ShrP {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.XR__sceneName___1ApHr {
  color: var(--white);
  margin: 0 1.5em;
}

.XR__sceneActions___7plGs {
  margin-left: auto;
  margin-right: 16px;
  margin-bottom: 16px;
}

.XR__actionButton___2poIM {
  position: relative;
  padding: 0;
  background: none;
  height: 54px;
  width: 54px;
  cursor:pointer;
  outline: none;
  text-decoration: none;
  border: none;
  border-radius: 30px;
  transition: all .3s ease-in-out;
  color: var(--white);
  fill: currentColor;
}

.XR__actionButton___2poIM:hover {
  background-color: var(--darkSecondaryColor);
  box-shadow: 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.3);
}

.XR__actionButton___2poIM:hover > .XR__tooltip___UYyhn {
  display: block;
}

.XR__actionButton___2poIM:hover > svg {
  filter: none;
}

.XR__actionButton___2poIM:focus {
  outline: none;
}

.XR__actionButton___2poIM > svg {
  width: 1.8em;
  height: 1.8em;
  filter: drop-shadow(1px 1px 1px var(--grey));
}

.XR__actionIcon___2qnd2 {
  fill: var(--white);
}

.XR__tooltip___UYyhn {
  display: inline;
  position: relative;
  margin: 0 0 0 16px;
}

.XR__autoShowTooltip___V1QH7 .XR__actionButton___2poIM {
  background-color: var(--darkSecondaryColor);
}

.XR__tooltip___UYyhn:after {
  background-color: var(--darkSecondaryColor);
  border-radius: 2px;
  bottom: 46px;
  color: var(--white);
  content: attr(data-text);
  text-decoration: none;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.XR__tooltip___UYyhn:before {
  border: solid;
  border-color: var(--darkSecondaryColor) transparent transparent transparent;
  border-width: 5px;
  bottom: 36px;
  content:"";
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  opacity: 0;
  transition: all .3s ease-in-out;
}


.XR__autoShowTooltip___V1QH7:after,
.XR__tooltip___UYyhn:hover:after {
  opacity: 1;
  transition-delay: 1s;
}

.XR__autoShowTooltip___V1QH7:before,
.XR__tooltip___UYyhn:hover:before {
  opacity: 1;
  transition-delay: 1s;
}

